/*eslint-disable*/
import React from "react"
import { Link } from "react-router-dom"

import IndexNavbar from "../components/Navbars/IndexNavbar.js"
import Footer from "../components/Footers/Footer.js"

export default function Index() {
  return (
    <>
      <IndexNavbar />
      <section className="px-4 sm:px-6 lg:px-12 py-8 lg:py-12">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col lg:flex-row items-center gap-8">
            {/* Content Column */}
            <div className="w-full lg:w-1/2 order-2 lg:order-1">
              <div className="space-y-6">
                <h2 className="font-semibold text-3xl sm:text-4xl lg:text-5xl text-slate-600">
                  El ATS que simplifica tu reclutamiento.
                </h2>

                <p className="text-lg sm:text-xl leading-relaxed text-slate-500">
                  Talenty es un Aplicant Tracking System gratuito, que te permite gestionar tu propia base de
                  candidatos, publicar vacantes en Google for Jobs, enviar feedbacks y evaluar Soft Skills con test
                  automatizados.
                </p>

                <div className="flex flex-col sm:flex-row gap-4 pt-4">
                  <Link
                    to="/auth/register"
                    className="inline-flex items-center justify-center px-6 py-3 rounded bg-rose-600 text-white font-bold text-sm uppercase hover:bg-rose-700 transition-colors duration-200 shadow hover:shadow-lg"
                  >
                    <i className="fas fa-check mr-2"></i>
                    Crear cuenta
                  </Link>

                  <Link
                    to="/auth/login"
                    className="inline-flex items-center justify-center px-6 py-3 rounded bg-indigo-500 text-white font-bold text-sm uppercase hover:bg-indigo-600 transition-colors duration-200 shadow hover:shadow-lg"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>

            {/* Image Column */}
            <div className="w-full lg:w-1/2 order-1 lg:order-2">
              <img
                className="w-full h-auto object-contain"
                src={require("assets/img/pattern_react.png")}
                alt="React Pattern Illustration"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mt-48 md:mt-40 pb-40 relative bg-slate-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="text-slate-100 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-indigo-600">
                <img
                  alt="..."
                  src="https://images.pexels.com/photos/2265488/pexels-photo-2265488.jpeg?auto=compress&cs=tinysrgb&w=640&h=427&dpr=1"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon points="-30,95 583,95 583,65" className="text-indigo-500 fill-current"></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">Agiliza tus proyectos de selección</h4>
                  <p className="text-md font-light mt-2 text-white">
                    Con nuestros Job Boards integra tus procesos de selección en un solo lugar. Puedes crear vacantes
                    ilimitadas y concentrar tu base de candidatos.
                  </p>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-th-large"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Job Boards</h6>
                      <p className="mb-4 text-slate-500">
                        Implementa Kanban en tus procesos de reclutamiento con los Job Boards de Talenty.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa fa-share"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Google for Jobs</h6>
                      <p className="mb-4 text-slate-500">
                        Publica de forma inmediata (y gratis) todas tus vacantes en Google for Jobs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa fa-users"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Pool de candidatos</h6>
                      <p className="mb-4 text-slate-500">
                        Gestiona tu propia base de datos de candidatos, organizalos y ten siempre su información a tu
                        alcance.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fas fa-file-alt"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Assesment center.</h6>
                      <p className="mb-4 text-slate-500">
                        Con Talenty puedes evaluar a tus candidatos fácilmente, adquiriendo test automatizados con
                        nuestros partners de Assesment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden pb-20">
          {/* JOB BOARDS SECTION*/}
          <div className="flex flex-wrap items-center pt-5">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform: "scale(1) perspective(1040px) rotateY(-11deg) rotateX(3deg) rotate(-1deg)",
                }}
                src={require("assets/img/documentation.png")}
              />
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-th-large text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">Job Boards</h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                ¡Recluta como los grandes! Con Talenty puedes crear Job Boards ilimitados, que te permitirán gestionar
                tus proyectos de selección en tableros Kanban, inspirados en procesos ágiles de la industria Tech.
              </p>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                Puedes añadir candidatos rápidamente, moverlos de una etapa a otra con sólo arrastrar y soltar, enviar
                feedback individual o masivo, agendar entrevistas y asignarles evaluaciones fácilmente.
              </p>

              <Link
                to="/auth/register"
                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
              >
                Crear mi primer Job Board <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </Link>
            </div>
          </div>
          {/* JOB BOARDS SECTION*/}

          {/* ASSESMENT CENTER SECTION*/}
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-file-alt text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">Assesment center.</h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-600">
                Elimina los sesgos en tu proceso de selección y contrata por habilidades. En nuestro Assesment Center
                puedes adquirir evaluaciones psicométricas y de competencias, compartirla a tus candidatos con un link
                único y obtener resultados de inmediato.
              </p>
              <div className="block pb-6">
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Kostik
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  IPV
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Zavic
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Inglés
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Wonderlic
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Terman
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  Gordon
                </span>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-white uppercase last:mr-0 mr-2 mt-2">
                  16PF
                </span>
              </div>
              <a
                href="/"
                target="_blank"
                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
              >
                Ver todas <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </a>
            </div>

            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={require("assets/img/wonderlic.png")}
                  className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/ipv.png")}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <img
                  alt="..."
                  src={require("assets/img/candidatos.png")}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                />

                <img
                  alt="..."
                  src={require("assets/img/results.png")}
                  className="w-full align-middle rounded absolute shadow-xl max-w-120-px left-195-px top-95-px"
                />
              </div>
            </div>
          </div>
          {/* ASSESMENT CENTER SECTION*/}

          {/* POOL CANDIDATOS SECTION*/}
          <div className="flex flex-wrap items-center pt-20 pb-10">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
              <div className="relative flex flex-col min-w-0 w-full mb-6 mt-48 md:mt-0">
                <img
                  alt="..."
                  src={require("assets/img/candidatos2.png")}
                  className="w-full align-middle rounded-lg absolute shadow-lg -top-160-px left-260-px max-w-210-px"
                />
                <img
                  alt="..."
                  src={require("assets/img/candidatos1.png")}
                  className="w-full align-middle rounded-lg absolute shadow-lg max-w-180-px -top-225-px left-40-px z-2"
                />
                <img
                  alt="..."
                  src={require("assets/img/candidatos3.png")}
                  className="w-full align-middle rounded-lg absolute shadow-2xl max-w-200-px -left-50-px top-25-px"
                />
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-users text-xl"></i>
              </div>
              <h2 className="font-semibold text-4xl">Pool de candidatos</h2>
              <h4 className="text-slate-500">
                ¿Aun sigues batallando por encontrar el CV de ese candidat@ que te pareció genial?{" "}
              </h4>
              <p className="text-lg leading-relaxed mt-4 mb-4 text-slate-500">
                Manten tu pool de candidatos actualizado. Talenty te permite gestionar tu cartera de candidatos, tener
                su perfil y documentos siempre a la mano, y filtrarlos fácilmente. Puedes añadirlos a un proceso de
                selección ó enviarle evaluaciones cuando quieras.
              </p>

              <Link
                to="/auth/register"
                className="font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
              >
                Comenzar... <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
              </Link>
            </div>
          </div>
          {/* POOL CANDIDATOS SECTION*/}
        </div>

        {/* GOOGLE FOR JOBS SECTION*/}
        <div className="container mx-auto px-4 pb-5 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-share text-xl"></i>
                </div>
                <h3 className="text-3xl font-semibold">Google for Jobs</h3>
                <h4 className="text-slate-500">Pon en linea tu vacante con Talenty.</h4>

                <p className="mt-4 text-lg leading-relaxed text-slate-500">
                  Todas tus vacantes pueden publicarse en Google for Jobs de manera gratuita, siguiendo las mejores
                  prácticas recomendadas para dar mayor visibilidad y probabilidad de recibir candidatos.
                </p>
                <p className="mt-4 text-lg leading-relaxed text-slate-500">
                  De igual forma, puedes compartir tu vacante en linkedin y redes sociales para comenzar a recibir
                  candidatos en tus Job Boards.
                </p>

                <Link
                  to="/auth/register"
                  className="mt-6 font-bold text-slate-700 hover:text-slate-500 ease-linear transition-all duration-150"
                >
                  Comenzar... <i className="fa fa-angle-double-right ml-1 leading-relaxed"></i>
                </Link>

                {/*  <ul className="list-none mt-6">
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Built by Developers for Developers
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="fab fa-html5"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Carefully crafted code for Components
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="flex items-center">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-slate-500 bg-slate-50 mr-3">
                          <i className="far fa-paper-plane"></i>
                        </span>
                      </div>
                      <div>
                        <h4 className="text-slate-500">
                          Dynamic Javascript Components
                        </h4>
                      </div>
                    </div>
                  </li>
                </ul> */}
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform: "scale(1) perspective(1020px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/googlejobs.png")}
              />
            </div>
          </div>
        </div>
        {/* GOOGLE FOR JOBS SECTION*/}
      </section>
      <section className="py-2 bg-slate-600 overflow-hidden">
        <div className="container mx-auto pb-64">
          <div className="flex flex-wrap justify-center">
            <div className="w-full md:w-5/12 px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <div className="text-slate-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fas fa-users text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">¿En búsqueda de talento?</h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-slate-400">
                Con{" "}
                <Link to="/auth/register" className="text-slate-300">
                  Talenty Jobs
                </Link>{" "}
                te ayudamos a encontrar el talento que tu empresa necesita.
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-slate-400">
                Nuestro servicio de headhunting profesional te permite conocer únicamente a los candidatos con potencial
                de formar parte de tu empresa a largo plazo!
              </p>
              <Link
                to="/auth/register"
                className="github-star mt-4 inline-block text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-700 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg"
              >
                Ver más...
              </Link>
            </div>

            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-64 relative">
              <i className="fa fa-users text-slate-700 absolute -top-150-px -right-100 left-auto opacity-80 text-55"></i>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-16 bg-slate-200 relative pt-10">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="text-slate-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
            <div className="w-full text-center lg:w-8/12">
              {/* <p className="text-4xl text-center">
                <span role="img" aria-label="love">
                  😍
                </span>
              </p> */}
              <h3 className="font-semibold text-3xl">Recluta con Talenty.</h3>
              <p className="text-slate-500 text-lg leading-relaxed mt-4 mb-4">
                Registrate hoy mismo y accede a todas las funcionalidades de Talenty ATS. Si aun tienes dudas, ponte en
                contacto con nosotros.
              </p>
              <div className="sm:block flex flex-col mt-10">
                <Link
                  to="/auth/register"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-sky-500 active:bg-sky-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  <i className="fa fa-user text-m mr-1"></i>
                  Registrarme
                </Link>
                <Link
                  to="/auth/register"
                  className="github-star sm:ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-slate-700 active:bg-slate-600 uppercase text-sm shadow hover:shadow-lg"
                >
                  <i className="fa fa-paper-plane text-m mr-1"></i>
                  <span>Contactarlos</span>
                </Link>
              </div>
              <div className="text-center mt-16"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
