import React, { useEffect, useState } from "react"
import { KeyIcon } from "@heroicons/react/24/outline"
import { Eye, EyeOff, Loader } from "lucide-react"
import { changePassword } from "../modules/Auth/AuthServices"

export default function ChangePassword() {
  const [processing, setProcessing] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessages, setErrorMessages] = useState({})
  const [successMessages, setSuccessMessages] = useState({})
  const [showAlert, setShowAlert] = useState(true)
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  useEffect(() => {
    if (
      (errorMessages || successMessages) &&
      (Object.keys(errorMessages).length > 0 || Object.keys(successMessages).length > 0)
    ) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 5000) // Hide after 5 seconds
      return () => clearTimeout(timer)
    }
  }, [errorMessages])

  const closeAlert = () => {
    setShowAlert(false)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (event) => {
    try {
      event.preventDefault()
      setProcessing(true)

      const {
        currentPassword: old_password,
        newPassword: new_password,
        confirmPassword: new_password_confirmation,
      } = formData

      const payload = { old_password, new_password, new_password_confirmation }

      const response = await changePassword(payload)
      const { success } = response

      if (success) {
        setErrorMessages({})
        setSuccessMessages("El correo se actualizó correctamente")
        setShowAlert(true)
        setFormData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        })
      }
    } catch (error) {
      setProcessing(false)
      setErrorMessages(error.errors)
      setShowAlert(true)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-md mx-auto">
        <div className="text-center mb-8">
          <KeyIcon className="mx-auto h-12 w-12 text-indigo-600" />
          <h2 className="mt-4 text-2xl font-bold text-gray-900">Cambiar Contraseña</h2>
          <p className="mt-2 text-sm text-gray-600">
            Asegúrate de usar una contraseña segura que no uses en otros sitios.
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow p-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative w-full mb-3">
              <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                Contraseña Actual
              </label>
              <div className="mt-1.5 relative">
                <input
                  id="currentPassword"
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  placeholder="••••••••"
                  className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                  required
                  value={formData.currentPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                </button>
              </div>
            </div>

            <div className="relative w-full mb-3">
              <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                Nueva Contraseña
              </label>
              <div className="mt-1.5 relative">
                <input
                  id="newPassword"
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  placeholder="••••••••"
                  className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                  required
                  value={formData.newPassword}
                  onChange={handleChange}
                  minLength={8}
                />
              </div>
            </div>

            <div className="relative w-full mb-3">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirmar Nueva Contraseña
              </label>
              <div className="mt-1.5 relative">
                <input
                  id="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  placeholder="••••••••"
                  className="block w-full appearance-none rounded-xl border-2 border-gray-200 bg-white px-4 py-3.5 placeholder-gray-400 shadow-sm transition duration-200 focus:border-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-500/20 hover:border-gray-300"
                  required
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  minLength={8}
                />
              </div>
            </div>

            {showAlert && Object.keys(errorMessages).length > 0 && (
              <div className="rounded-xl bg-red-100 p-4 border border-red-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <button onClick={closeAlert} className="text-red-500 hover:text-red-700">
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="ml-3">
                      {" "}
                      {Object.keys(errorMessages).map((key) => (
                        <p className="text-sm text-red-700 font-medium" key={key}>
                          {" "}
                          {errorMessages[key].join(", ")}{" "}
                        </p>
                      ))}{" "}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showAlert && Object.keys(successMessages).length > 0 && (
              <div className="rounded-xl bg-green-100 p-4 border border-green-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <button onClick={closeAlert} className="text-green-500 hover:text-green-700">
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-green-700 font-medium">{successMessages}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-70 disabled:cursor-not-allowed"
                disabled={processing}
              >
                {processing ? (
                  <>
                    <Loader className="mr-2 h-4 w-4 animate-spin" />
                    <span>Procesando...</span>
                  </>
                ) : (
                  <span className="group-hover:scale-105 transition-transform duration-200">Actualizar Contraseña</span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
