import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Mail, ArrowLeft } from "lucide-react"

export const EmailSent = () => {
  const location = useLocation()
  const { email } = location.state || {}

  return (
    <div className="min-h-screen bg-gradient-to-br from-white via-white to-purple-400">
      <div className="flex min-h-screen flex-col justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-2xl sm:rounded-2xl sm:px-10 border border-gray-300 relative overflow-hidden">
            <div className="absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600" />
            <div className="text-center">
              <Mail className="mx-auto h-12 w-12 text-indigo-600" />
              <h3 className="mt-4 text-xl font-medium text-gray-900">Revisa tu email</h3>
              <p className="mt-2 text-sm text-gray-500">
                Te hemos enviado las instrucciones para recuperar tu contraseña a{" "}
                <label className="font-bold">{email}</label>
              </p>
              <p className="mt-4 text-sm text-gray-500">Si no encuentras el email, revisa tu carpeta de spam.</p>
            </div>
            <div className="mt-6 text-center">
              <Link
                to="/auth/login"
                className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Volver al inicio de sesión
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
