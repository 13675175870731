import React, { useState } from "react"
import { Switch } from "@headlessui/react"
import { BellIcon, GlobeAltIcon, LockClosedIcon, UserIcon } from "@heroicons/react/24/outline"

export default function Settings() {
  const [notifications, setNotifications] = useState(true)
  const [newsletter, setNewsletter] = useState(false)
  const [privacy, setPrivacy] = useState(true)

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 mb-8">Configuración de la Cuenta</h1>

        <div className="bg-white rounded-2xl shadow divide-y divide-gray-200">
          {/* Profile Section */}
          <div className="p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <UserIcon className="h-6 w-6 text-gray-400" />
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900">Información Personal</h3>
                  <p className="text-sm text-gray-500">Actualiza tu información personal y de perfil</p>
                </div>
              </div>
              <button className="px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-500">Editar</button>
            </div>
          </div>

          {/* Notifications Section */}
          <div className="p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <BellIcon className="h-6 w-6 text-gray-400" />
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900">Notificaciones</h3>
                  <p className="text-sm text-gray-500">Administra tus preferencias de notificaciones</p>
                </div>
              </div>
              <Switch
                checked={notifications}
                onChange={setNotifications}
                className={`${
                  notifications ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                <span className="sr-only">Activar notificaciones</span>
                <span
                  className={`${
                    notifications ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </div>

          {/* Newsletter Section */}
          <div className="p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <GlobeAltIcon className="h-6 w-6 text-gray-400" />
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900">Newsletter</h3>
                  <p className="text-sm text-gray-500">Recibe nuestras actualizaciones por email</p>
                </div>
              </div>
              <Switch
                checked={newsletter}
                onChange={setNewsletter}
                className={`${
                  newsletter ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                <span className="sr-only">Activar newsletter</span>
                <span
                  className={`${
                    newsletter ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </div>

          {/* Privacy Section */}
          <div className="p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <LockClosedIcon className="h-6 w-6 text-gray-400" />
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900">Privacidad</h3>
                  <p className="text-sm text-gray-500">Configura tus opciones de privacidad</p>
                </div>
              </div>
              <Switch
                checked={privacy}
                onChange={setPrivacy}
                className={`${
                  privacy ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
              >
                <span className="sr-only">Activar privacidad</span>
                <span
                  className={`${
                    privacy ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
