import React from "react"
import { QuestionMarkCircleIcon, ChatBubbleLeftRightIcon, DocumentTextIcon } from "@heroicons/react/24/outline"

export default function Support() {
  const supportOptions = [
    {
      title: "Centro de Ayuda",
      description: "Encuentra respuestas a las preguntas más frecuentes",
      icon: QuestionMarkCircleIcon,
      link: "#",
    },
    {
      title: "Chat en Vivo",
      description: "Contacta con nuestro equipo de soporte",
      icon: ChatBubbleLeftRightIcon,
      link: "#",
    },
    {
      title: "Documentación",
      description: "Consulta nuestras guías y tutoriales",
      icon: DocumentTextIcon,
      link: "#",
    },
  ]

  return (
    <>
      {/* Page Title */}
      <div className="mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Soporte</h2>
        <p className="text-gray-600">¿Cómo podemos ayudarte?</p>
      </div>

      {/* Search Bar */}
      <div className="max-w-2xl mb-8">
        <input
          type="text"
          placeholder="Buscar ayuda..."
          className="w-full px-4 py-2 rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      {/* Support Options Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {supportOptions.map((option, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow">
            <option.icon className="h-8 w-8 text-indigo-600 mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">{option.title}</h3>
            <p className="text-gray-500 mb-4">{option.description}</p>
            <a href={option.link} className="text-indigo-600 hover:text-indigo-500 font-medium text-sm">
              Saber más →
            </a>
          </div>
        ))}
      </div>
    </>
  )
}
