import { apitalenty } from "hooks/useAPIaxios"

export default async function ShowVacante(id) {
  const apiInstance = apitalenty()
  try {
    const response = await apiInstance.get(`jobs/${id}`)

    if (response.status !== 200) {
      return Promise.reject({ vacante: ["No se encontraron datos"] })
    }

    return response.data
  } catch ({ response }) {
    throw response.data.errors
  }
}
