import { apitalenty } from "hooks/useAPIaxios"

export default async function PostulacionStore(data) {
  const apiInstance = apitalenty()
  try {
    const response = await apiInstance.post("postularse/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    if (response.status !== 200) {
      return Promise.reject({ vacante: ["No se pudo actualizar la postulación"] })
    }

    return response.data
  } catch ({ response }) {
    throw response.data.errors
  }
}
