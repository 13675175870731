import { useState } from "react"

export const useLocalStorage = (key, initialValue) => {
  const storedValue = localStorage.getItem(key)
  const initial = storedValue ? JSON.parse(storedValue) : initialValue

  const [value, setValue] = useState(initial)

  const setStoredValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value
      setValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.log(error)
    }
  }

  return [value, setStoredValue]
}
