// Profile Page (pages/Profile.js)
import React, { useContext } from "react"
import { CheckBadgeIcon, EnvelopeIcon, MapPinIcon, PhoneIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import { AuthContext } from "../contexts/AuthContext"

export default function Profile() {
  const {
    user: { user },
  } = useContext(AuthContext)

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-2xl shadow">
        {/* Header/Cover */}
        <div className="h-48 rounded-t-2xl bg-gradient-to-r from-blue-500 to-indigo-600"></div>

        {/* Profile Info */}
        <div className="relative px-6 pb-6">
          <div className="flex flex-col sm:flex-row items-center">
            <div className="-mt-16 mb-4 sm:mb-0">
              <UserCircleIcon className="h-32 w-32 rounded-full bg-white text-gray-700 border-4 border-white shadow-lg" />
            </div>
            <div className="sm:ml-6 text-center sm:text-left">
              <h1 className="text-2xl font-bold text-gray-900">{user.name}</h1>
              <p className="text-gray-500 mt-1">{user.bio}</p>
            </div>
          </div>

          {/* Contact Information */}
          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex items-center p-4 bg-gray-50 rounded-xl">
              <EnvelopeIcon className="h-6 w-6 text-gray-400" />
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Email</p>
                <p className="text-sm text-gray-500">{user.email}</p>
              </div>
            </div>
            <div className="flex items-center p-4 bg-gray-50 rounded-xl">
              <PhoneIcon className="h-6 w-6 text-gray-400" />
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Teléfono</p>
                <p className="text-sm text-gray-500">{user.phone}</p>
              </div>
            </div>
            <div className="flex items-center p-4 bg-gray-50 rounded-xl">
              <MapPinIcon className="h-6 w-6 text-gray-400" />
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Ubicación</p>
                <p className="text-sm text-gray-500">{user.location}</p>
              </div>
            </div>
            <div className="flex items-center p-4 bg-gray-50 rounded-xl">
              <CheckBadgeIcon className="h-6 w-6 text-gray-400" />
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-900">Email Verificado</p>
                <p className="text-sm text-gray-500">{user.email_verified_at || "No verificado"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
